@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&family=Titan+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ribeye&family=Ribeye+Marrow&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

@font-face {
  font-family: myfont;
  src: url(./assets/twinklediamond.ttf);
  /* src: url(./assets/odenaglamour.ttf); */
}


.App {
  text-align: center;
  font-family: 'raleway';
  background-color: white;
  background-image: linear-gradient(#f1f1fc, #afadec);
}

.Nav {
  position: absolute;
  /* top: 0; */
  z-index: 50;
  width: 100%;
}

.navItems {
  display: grid;
  grid-template-columns: 11fr 1fr 1fr 1fr 1fr;
  list-style: none;
  gap: 30px;
  align-items: center;
  justify-items: center;
  padding: 20px 0;
  margin: 0 60px;
}

.navItems>li>a {
  text-decoration: none;
  /* font-family: 'ribeye'; */
  color: #219897;
  font-size: 16px;
  /* transition: color 0.8s; */
  font-weight: 700;
}

.navItems>li>button,
.navItems>li>div {
  border: none;
  background-color: transparent;
  color: #219897;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.navItems>li>*:hover {
  color: #7d4bba;
}

button {
  font-family: inherit;
  cursor: pointer;
}

.teamswitchbtn {
  color: #219897;
  font-size: 16px;
  font-weight: 700;
  border: none;
  background-color: transparent;
  align-self: flex-end;
  margin-top: 4px;
  margin-right: 20px;
}

.teamswitchbtn:hover {
  color: #7d4bba;
}

.logoDiv {
  justify-self: left;
}

.Logo {
  height: 60px;
}


.Welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 101vh;
  min-height: 101dvh;
  min-height: 101lvh;
  background-color: white;
  background-image: url(./assets/bgg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: linear-gradient(to bottom right,#ffffff, #b683f4); */

  max-width: 100%;
}

.Welcome {
  position: relative;
  z-index: 40;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: rgba(171, 26, 255, 0.35) 0px 2px 10px;
  /* position: sticky; */
  /* top: 0; */
  /* transform: translateY(calc(-1% * (max(var(--scroll), 25) - 25) * 100 / 75)); */
  /* (oldval - oldmin) * newrange / oldrange + newmin */
  /* 25 = oldMin
  100= newRange
  75= oldRange
  0=newMin */
  /* opacity:0.3; */
}

.welcomeText,
.logoBgdiv {
  position: absolute;
  width: 100%;
  color: #3993a8;
  font-size: 80px;
  font-family: myfont;
  text-shadow: 1px 1px 4px #ffffff;
}

.welcomeText {
  z-index: 41;
}

.logoBg {
  opacity: 10%;
}

.Main {
  display: grid;
  grid-template-columns: 2.5fr 2fr;
  margin-top: 80px;
}

.mainWritten {
  justify-self: left;
  align-self: top;
  padding: 50px 50px;
  font-size: 20px;
  /* color: #b683f4; */
  color: #7d4bba;
  text-align: left;
}

.mainWritten>h1 {
  color: #219897;
  font-weight: 400;
  font-family: myfont;
  text-shadow: 1px 1px 4px #ffffff;
  text-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3), 0px -4px 10px rgba(255, 255, 255, 0.3);
}

/* .mainWritten>div>span{
  display: inline-block;
} */
.mainWritten>p {
  margin: 0;
  font-size: 18px;
}

.mainImg {
  justify-self: left;
  /* height: 530px; */
  width: 40vw;
  width: 40dvw;
  width: 40svw;
  object-fit: cover;
}


.SpotlightModal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.301);
  backdrop-filter: blur(4px);
  z-index: 60;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

.modalBloomDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* max-width: 50%; */
  position: relative;
}

.spotlightHead {
  font-family: myfont;
  font-size: 48px;
  color: white;
  text-shadow: 1px 1px 4px #000000;
}

.modalBloom {
  background-color: white;
  border-radius: 25px;
  box-shadow: rgba(39, 7, 58, 0.25) 0px 2px 8px;
  padding: 2px 4px;
  width: max-content;
}

.modalCloseBtn {
  position: absolute;
  top: -20px;
  right: -20px;
  color: white;
  border: none;
  place-self: end;
  font-size: 20px;
  background-color: transparent;
}

.bloomPoster {
  object-fit: cover;
  height: 70vh;
  width: 70vh;
  border-radius: 25px;
  /* cursor: pointer; */
}



.About {
  height: 200dvh;
  margin-top: -100dvh;
  height: 200lvh;
  margin-top: -100lvh;
  /* background-color: bisque; */
  /* padding: 0 100px; */
  z-index: 30;
  position: relative;
}

.about_child {
  height: 100vh;
  height: 100dvh;
  height: 100lvh;
  position: sticky;
  top: 0;
  background-color: #f8efe5;
  /* background-image: linear-gradient(#ffffff, #aadeffcc); */
  background-image: linear-gradient(#ffffff, #dbbdff);
  /* background-image: url(./assets/bgg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  /* border-bottom-left-radius: 100px; */
  /* border-bottom-right-radius: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* box-shadow: rgba(171, 26, 255, 0.35) 0px 2px 10px;  */
}

@keyframes about-scalein {

  /*wall text*/
  0% {
    opacity: 0.5;
    transform: translateY(1100%) scale(0.3);
  }

  90% {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }

  100% {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
}

@keyframes cards-scalein {

  /*wall text*/
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bee-fade-in {

  /*wall text*/
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.aboutHeading {
  font-size: 56px;
  font-family: myfont;
  text-shadow: 1px 1px 4px #ffffff;
  color: #219897;
  padding-top: 30px;
  /* height: 400px; */
  z-index: 31;
  text-shadow: 2px 4px 5px rgba(0, 0, 0, 0.3), 0px -4px 10px rgba(255, 255, 255, 0.3);

}

.aboutMain {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  /* gap: 20px; */
  margin: 0px 0 30px;
}

.aboutBee {
  /* height: 50px; */
  object-fit: cover;
  opacity: 0;
  /* animation: bee-fade-in linear forwards;
  animation-timeline: view();
  animation-range-start: contain 80%;
  animation-range-end: contain 88%; */
}




.aboutCards {
  position: relative;
  background-color: rgb(0, 0, 0);
  border-radius: 25px;
  height: 300px;
  width: 300px;
  box-shadow: rgba(171, 26, 255, 0.5) 0px 2px 8px;
  opacity: 0;
  transform: scale(0.5);
  /* animation: cards-scalein linear forwards;
  animation-timeline: view();
  animation-range-start: contain 35%;
  animation-range-end: contain 70%; */
}

.aboutCards>img {
  object-fit: cover;
  height: 100%;
  border-radius: 25px;
  width: 100%;
  opacity: 50%;
}

.aboutCardsContent {
  position: absolute;
  top: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 67% 33%;
  height: 100%;
  width: 100%;
  gap: 20px;
}

@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.aboutCardsHead.one {
  animation: counter 2s linear forwards;
  counter-reset: num var(--num);
}

.aboutCardsHead.one::after {
  content: counter(num) '+';
}

@keyframes counter {
  from {
    --num: 0;
  }

  to {
    --num: 100;
  }
}

.aboutCardsHead.two {
  animation: counter2 2s linear forwards;
  counter-reset: num var(--num);
}

.aboutCardsHead.two::after {
  content: counter(num) '+';
}

@keyframes counter2 {
  0% {
    --num: 0;
  }

  60% {
    --num: 60;
  }

  100% {
    --num: 20;
  }
}

.aboutCardsHead.three {
  animation: counter3 2s linear forwards;
  counter-reset: num var(--num);
}

.aboutCardsHead.three::after {
  content: counter(num) '+';
}

@keyframes counter3 {
  0% {
    --num: 0;
  }

  55% {
    --num: 60;
  }

  100% {
    --num: 10;
  }
}

.aboutCardsHead {
  align-self: end;
  font-size: 60px;
  /* margin-bottom: 20px; */
  /* margin-top: 40px; */
  color: white;
  z-index: 31;
}

.aboutCardsText {
  color: white;
  margin: 0 20px 20px;
  /* margin-bottom: 20px; */
}


.EventsContainer {
  /* height: 300vh; */
  /* margin-top: -200dvh; */
  z-index: 30;
  position: relative;
}

.Events {
  z-index: 30;
  /* position: sticky;  */
  /* top: 0;  */
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  height: 130vh;
  height: 130dvh;
  /* max-height: 100dvh; */
  height: 130lvh;
  /* max-height: 100lvh; */
  background-color: #f8efe5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  box-shadow: 0px 8px 12px 0px rgba(48, 13, 69, 0.2);
  /* box-shadow: rgba(8, 8, 8, 0.35) 0px 2px 10px; */
  background-image: linear-gradient(#dbbdff, #ffffff);
  /* background-image: linear-gradient(#aadeffcc ,#ffffff, #e3cbff); */
}


.carousel {
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  transition: transform 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  max-width: 100%;
}

/* .eventsCarousel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  background-color: #fff;
  /* min: 100vw; 
} */

/* .carousel-item-text {
  font-size: 1.15rem;
  margin: 40px 0 40px 0;
  padding: 0 20px 0 20px;
  white-space: normal;
  color: #617d98;
}
.carousel-img {
  width: 250px;
  padding: 0 20px 0 20px;
} */


.carousel-buttons {
  position: relative;
}

.button-arrow1,
.button-arrow2 {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 40px;
  color: #543c72;
  position: absolute;
  bottom: 100px;
  left: 130px
}

.button-arrow2 {
  left: 180px
}

/* .indicators {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;  
}
.indicator-buttons {
  border: none;
  cursor: pointer;
}
.indicators > button {
  margin: 5px;
  background: none;
}
.indicator-symbol {
  color: #26343f;
}
.indicator-symbol-active {
  color: #ffffff;
} */

.eventsCarouselCon {
  max-width: 100%;
}

.eventsCarousel {
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  text-align: left;
  justify-items: center;
  width: 100vw;
  width: 100dvw;
  width: 100svw;
  max-width: 100%;
  height: 100vh;
  height: 100dvh;
  height: 100lvh;
  max-height: 100%;
}

.eventsHeading {
  font-size: 64px;
  font-family: myfont;
  text-shadow: 1px 1px 4px #ffffff;
  color: rgb(231, 118, 118);
}

.eventsSubHeading {
  font-size: 40px;
  font-family: myfont;
  text-shadow: 1px 1px 4px #ffffff;
  font-weight: 400;
  color: rgb(231, 118, 118);
  margin-bottom: 10px;
}

.bentoGrid {
  display: grid;
  max-width: 50vw;
  max-height: 98vh;
  grid-template-columns: repeat(3, 190px);
  grid-template-rows: repeat(3, 170px);
  gap: 8px;
  justify-content: center;
  justify-self: start;
  margin-bottom: 8px;
}

.bentoGrid.bento2 {
  display: grid;
  grid-template-columns: repeat(2, 285px);
  grid-template-rows: repeat(2, 255px);
  gap: 8px;
  justify-content: center;
  justify-self: start;
  margin-bottom: 8px;
}

.bentoGrid>div {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(171, 26, 255, 0.8) 0px 4px 12px;
  border-radius: 15px;
  /* height: 140px; */
  /* width: 190px; */
  overflow: hidden;
}

.bentoGrid>div:hover {
  box-shadow: rgba(255, 255, 255, 0.7) 0px 0px 12px;
}

.bentoGrid>div>img {
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s;
}

.bentoGrid>div>img:hover {
  object-fit: cover;
  transform: scale(1.1);
}

.event1 {
  grid-row: 1/3;
}

.event2 {
  grid-column: 2/4;
}

@keyframes shine {
  from {
    background-color: #afadec
  }

  to {
    background-color: #543c72;
  }
}

.bentoGrid>.event3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-family: myfont;
  color: white;
  cursor: pointer;
  background-color: #afadec;
  border: 2px solid transparent;
  animation-duration: 1s;
  animation-name: shine;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* .bentoGrid>.event3:hover{
  border: 2px solid #f85b70;
} */
.event5 {
  grid-column: 1/3;
}

.EventModal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.301);
  backdrop-filter: blur(4px);
  z-index: 60;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

.EventDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 50%;
  position: relative;
  z-index: inherit;
}

.AboutEvent {
  background-color: white;
  border-radius: 25px;
  box-shadow: rgba(39, 7, 58, 0.25) 0px 2px 8px;
  padding: 16px 16px;
}

.AboutEvent>p {
  width: 100%;
}

.modalCloseBtn {
  /* position: absolute; */
  color: white;
  border: none;
  place-self: end;
  font-size: 20px;
  background-color: transparent;
}

.eventPosterFlex {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.EventPoster {
  object-fit: cover;
  height: 200px;
  /* min-width: 300px; */
  border-radius: 15px;
}


/* .FacultyLayer{
  z-index: 20;
  position: relative;
  height: 100vh;
  height: 100dvh;
  height: 100lvh;
  display: grid;
  grid-template-columns: 2.5fr 3fr;
  align-items: center;
} */

@keyframes faculty-parallax {

  /*wall text*/
  0% {
    opacity: 0.5;
    transform: translateY(250%);
  }

  /* 90%{
    opacity:1;
    transform: translateY(0%) scale(1);
  } */
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes faculty-scalein {

  /*wall text*/
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.facultyLayerContent {
  justify-self: left;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  /* gap: 25px; */
  justify-content: space-between;
  height: 500px;
}

.facultyHeading,
.founderHeading {
  font-size: 56px;
  /* padding: 50px 0 0; */
  /* margin-top: 64px; */
  /* font-weight: 600; */
  /* margin-left: 275px; */
  font-family: myfont;
  text-shadow: 1px 1px 4px #ffffff;
  color: #219897;
  text-align: left;
}

.facultyName {
  text-align: left;
  font-size: 36px;
  color: #543c72;
}

.FacultyContainer {
  min-height: 400vh;
  margin-top: -200vh;
  /* min-height: 400vh;
  margin-top: -300vh; */
}

.Faculty {
  position: sticky;
  top: 0;
  z-index: 15;
  min-height: 200vh;
  min-height: 200dvh;
  min-height: 200lvh;
  background-color: #f8efe5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* box-shadow: rgba(255, 255, 255, 0.35) 0px 2px 50px; */
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: rgba(171, 26, 255, 0.35) 0px 2px 10px;
  background-image: url(./assets/bg1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.facultyContent {
  /* margin:0px 0 100px; */
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
  display: grid;
  grid-template-columns: 2.5fr 3fr;
  align-items: center;
  justify-items: center;
  gap: 50px;
  padding: 0 50px;
  height: 100vh;
  height: 100dvh;
  height: 100lvh;
}

/* .FacultyMobile{display: ;} */
.facultyImgDiv {
  height: 300px;
  min-width: 300px;
  background-color: white;
  border-radius: 25px;
  /* box-shadow: rgba(171, 26, 255, 0.35) 0px 2px 10px; */
  box-shadow: rgba(11, 11, 11, 0.496) 0px 4px 12px;

  /* justify-self: right; */
}

.facultyImg {
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  /* width: 300px; */
}

.facultyText {
  justify-self: left;
  text-align: left;
  width: 90%;
  font-size: 15px;
}

.foundersContent {
  height: 100vh;
  height: 100dvh;
  height: 100lvh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  justify-items: center;
  padding: 0 20px;
}

.foundersContentMobile {
  display: none;
}

.founderImgDiv {
  width: 100%;
  height: 100%;
  max-height: 300px;
  align-self: start;
  transform: translateY(-20%);
}

.founderImgDiv.shivam {
  transform: translateY(20%);
  align-self: end;
}

.founderImg {
  /* height: 15rem; */
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 25px;
}

.founderHeading {
  font-size: 72px;
}

.teamContainer {
  height: 300dvh;
  margin-top: -200dvh;
  height: 300lvh;
  margin-top: -200lvh;
  z-index: 10;
  position: relative;
  background-color: #ffffff;
}

.Team2 {
  z-index: 10;
  position: sticky;
  top: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  min-height: 100vh;
  min-height: 100dvh;
  min-height: 100lvh;

  /* max-height: max-content; */
  background-color: #f8efe5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* box-shadow: rgba(171, 26, 255, 0.35) 0px 2px 10px;  */
  /* opacity: 0.5;  */
  /* background-image: url(./assets/bg.png); */
  background-image: linear-gradient(#aadeffcc, #ffffff);
  background-repeat: no-repeat;
  background-size: cover;
}


/* .Team2{background-color: rgb(148, 124, 172);} */

.teamHeading {
  font-size: 56px;
  /* padding: 4px 0 0; */
  /* font-weight: 600; */
  /* margin-left: 275px; */
  font-family: myfont;
  text-shadow: 1px 1px 4px #000000;
  color: #ffffff;
  line-height: 0.7;
}


.teamCarousel {
  display: flex;
  align-items: center;
  gap: 25px;
  overflow-x: scroll;
  margin-top: 20px;
  height: 500px;
}

.teamCarousel::-webkit-scrollbar {
  display: none;
}

.teamMember_dummy {
  background-color: white;
  height: 350px;
  min-width: 200px;
  border-radius: 25px;
  opacity: 0;
}

.teamMember {
  background-color: rgb(255, 255, 255);
  height: 340px;
  min-width: 190px;
  border-radius: 25px;
  opacity: 1;
  cursor: pointer;
  /* border: 0.5px solid #b683f45f; */
  box-shadow: rgba(171, 26, 255, 0.35) 0px 2px 10px;
}

.teamMember>img {
  object-fit: cover;
  border-radius: 25px;
  WIDTH: 100%;
  height: 100%;
}

.teamMember.full {
  min-width: 55vw;
}

.center .slick-center {
  transform: scale(1.3, 1.28);
  z-index: 11;
  transition: all 400ms ease-in-out;
}

.center .slick-center>div>.teamMember {
  opacity: 1;
}

.slick-track {
  margin-top: 65px;

}

.slick-slider {
  height: 500px;
  margin-left: -700px;
  /*-500 pehle*/
}

.slick-list {
  margin-bottom: 100px;
  height: 480px;
  overflow-x: scroll;
}

.slick-list::-webkit-scrollbar {
  display: none;
}

.slick-slide>div {
  height: 350px;
  min-width: 200px;
  width: 200px;
  border-radius: 25px;
}

.SliderContainer {
  position: relative;
}

.next,
.back {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
  color: #ce4165;
  border: none;
  position: absolute;
  right: 35%;
  z-index: 12;
  bottom: 2px;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(171, 26, 255, 0.35) 0px 2px 10px;
}

.back {
  left: 35%;
}


/*@keyframes scale-member {
  0%{
    scale:1
  }
  50%{
    scale: 1.5;
  }
  100%{
    scale: 1;
  }
} */


@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(0px) scale(1);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }

}






/* .one{
      animation: fade-in 0.5s;
  } */

.Home_nav {
  animation: fade-in 2s ease;
}


@keyframes scale-in {

  /*wall text*/
  0% {
    opacity: 0;
    /* transform: translateY(-220%) scale(0.8);  */
    transform: translateY(-220%) scale(0.5);
  }

  11.5% {
    opacity: 0.3;
    /* transform: translateY(0) scale(0.9); */
    transform: translateY(0) scale(0.6);
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    /* transform: scale(1.5);  */
    transform: scale(1);
  }
}

.Wall {
  height: 500dvh;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  position: relative;
  z-index: 8;
  background-color: #f8efe5;
  /* box-shadow: rgba(171, 26, 255, 0.25) 0px 2px 10px;  */
  background-image: linear-gradient(#ffffff, #afadec);
}

.wallTextDiv,
.wallTextDiv_mobile {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 120px;
  font-weight: 700;
  position: sticky;
  /* overflow-x: hidden; */
  top: 0;
}

/* .walltextcon{
  display: flex;
  align-items: center;
  height: 200px;
  justify-content: center;
  /* style={{position:'relative',overflowX:'hidden', width:'100%'}} 
} */
.wallText {
  opacity: 0;
  transform: translateY(-220%) scale(0.2);
  color: #3993a8;
  width: 100%;
  position: relative;
  /* min-height: 100dvh; */
  /* padding: 100px 0; */
  background-image: linear-gradient(#0b5f6e, #00a3c8);
  /* background-image: url('./assets/wallcolor.jpg'); */
  background-clip: text;
  color: transparent;
  /* background-size: cover; */
  /* box-shadow: 1px 1px 4px #ffffff; */

}

.wallTextDiv_mobile {
  display: none;
}

/* @keyframes slide-in-six{ 
  0%{
    transform: translateY(220%); 
  }
  20%{
    transform: translateY(220%) rotate(5deg); 
  }
  30%{
    transform: translateY(0)  rotate(5deg)
  }
  74%{
    transform: translateY(0) rotate(5deg)
  }
  80%{
    transform: translateY(0)  rotate(1deg)
  }
  90%{
    transform: translateY(-220%)  rotate(0deg);
  }
  100%{
    transform: translateY(-220%)
  }
} */

@keyframes slide-in-un {

  /*wall Cards*/
  0% {
    transform: translateY(220%);
  }

  20% {
    transform: translateY(220%) rotate(-5deg);
  }

  30% {
    transform: translateY(0) rotate(-5deg)
  }

  66% {
    transform: translateY(0) rotate(-5deg)
  }

  72% {
    transform: translateY(0) rotate(-1deg)
  }

  82% {
    transform: translateY(-220%) rotate(0deg);
    /* opacity: 1; */
  }

  100% {
    transform: translateY(-220%);
    /* opacity: 0; */
  }
}

@keyframes slide-in-deux {

  /*wall Cards*/
  0% {
    transform: translateY(220%);
  }

  20% {
    transform: translateY(220%) rotate(0deg);
  }

  30% {
    transform: translateY(0) rotate(0deg)
  }

  58% {
    transform: translateY(0) rotate(0deg)
  }

  64% {
    transform: translateY(0) rotate(3deg)
  }

  74% {
    transform: translateY(-220%) rotate(0deg);
  }

  100% {
    transform: translateY(-220%)
  }
}

@keyframes slide-in-trois {

  /*wall Cards*/
  0% {
    transform: translateY(220%);
  }

  20% {
    transform: translateY(220%) rotate(5deg);
  }

  30% {
    transform: translateY(0) rotate(5deg)
  }

  50% {
    transform: translateY(0) rotate(5deg)
  }

  56% {
    transform: translateY(0) rotate(2deg)
  }

  66% {
    transform: translateY(-220%) rotate(0deg);
    /* opacity: 1; */
  }

  100% {
    transform: translateY(-220%);
    /* opacity: 0; */
  }
}

@keyframes slide-in-quatre {

  /*wall Cards*/
  0% {
    transform: translateY(220%);
  }

  20% {
    transform: translateY(220%) rotate(-3deg);
  }

  30% {
    transform: translateY(0) rotate(-3deg)
  }

  42% {
    transform: translateY(0) rotate(-3deg)
  }

  48% {
    transform: translateY(0) rotate(0deg)
  }

  58% {
    transform: translateY(-220%) rotate(0deg);
  }

  100% {
    transform: translateY(-220%)
  }
}

@keyframes slide-in-cinq {

  /*wall Cards*/
  0% {
    transform: translateY(220%);
  }

  20% {
    transform: translateY(220%) rotate(3deg);
  }

  30% {
    transform: translateY(0) rotate(3deg)
  }

  35% {
    transform: translateY(0) rotate(2deg)
  }

  40% {
    transform: translateY(0) rotate(1deg)
  }

  50% {
    transform: translateY(-220%) rotate(0deg);
  }

  100% {
    transform: translateY(-220%)
  }
}

.wallCardsDiv {}

.wallCards {
  position: absolute;
  height: 240px;
  width: 420px;
  border-radius: 25px;
  background-color: white;
  box-shadow: rgba(171, 26, 255, 0.25) 0px 2px 10px;
  /* animation: slide-in 0.5s;  */
}



.Support {
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  /* padding: 80px 80px; */
}

.supportFormDiv {
  min-height: 80vh;
  min-width: 90vw;
  /* min-width: ; */
  /* margin: 80px 80px; */
  border-radius: 25px;
  background-color: white;
  box-shadow: rgba(171, 26, 255, 0.25) 0px 2px 10px;
}


.footerContainer {
  min-height: 1000vh;
  margin-top: -900vh;
}

.Footer {
  position: sticky;
  top: 0;
  z-index: 0;
  /* position: relative; */
  min-height: 100vh;
  min-height: 100dvh;
  min-height: 100lvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #f8efe5; */
  background-color: #afadec;
}

.footerjoinUs {
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: rgba(171, 26, 255, 0.20) 0px 2px 10px;

  /* background-color: rgb(255, 165, 165); */
  background-color: #ffffff;
  min-height: 55vh;
  min-height: 55dvh;
  min-height: 55lvh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: center; */
  align-content: center;
  color: #7d4bba;
}

.joinUs {
  padding-left: 70px;
  justify-self: center;
  align-self: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  text-align: left;
}

.joinUs>h1 {
  text-align: left;
  margin: 0;
  /* font-family: 'bangers'; */
  text-shadow: 1px 1px 4px #ffffff;
}

.askQuesBtn {
  border: none;
  border-radius: 25px;
  background-color: #afadec;
  padding: 8px 16px;
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 8px;
}


.footerContent {
  /* display: flex;
  flex-direction: column; */
  align-items: end;
  height: 100%;
  justify-content: center;
  grid-row-gap: 40px;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  padding: 0 60px;
}

.footerContent_mobile {
  display: none;
  flex-direction: column;
}

.footerLogo {
  height: 80px;
}

.footerContact {
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0px;
}

.footerLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 12px;
}

.footerIcons {
  height: 40px;
  transition: transform 0.2s;
}

.footerIcons:hover {
  transform: scale(1.1);
}

.footerBMS {

  grid-column: 1/4;
  width: 100%;
  margin-bottom: 0;
  height: max-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.footerBMS>.footerbgdesk,
.footerBMS>.footerbgmobile {
  object-fit: cover;
  width: 100%;
}

.footerbgmobile {
  display: none;
}

.footerCopyright {
  padding-left: 8px;
}

.footerCredits {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  justify-content: flex-end;
}

.footerCreditsNames {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: right;
  justify-content: flex-end;
}

.BloomPage {
  /* background-color: white; */
  /* background-image: linear-gradient(to bottom right, #ffffff, #f29cb49a, #9899F5); */
}

.BloomBanner {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  height: 80vh;
  margin: 0px 60px;
  padding: 40px 0;
  font-size: 28px;
  font-family: 'roboto serif';
  /* text-shadow: 1px 1px 4px #000000c9; */
  color: #2D2932;
  font-weight: 400;
  /* justify-content: center; */
  position: relative;
}

.bloomNav {
  position: absolute;
  top: 40px;
  right: 0;
}

.bloomBannerLogo {
  text-align: left;
}

.bloomBannerLogo>div {
  text-align: right;
  margin-top: 20px;
}

.bloomBannerHead {
  align-self: center;
  font-size: 8rem;
  font-family: myfont;
  font-weight: 400;
  color: white;
  text-shadow: 1px 1px 4px #000000;
}

.bloomBannerText {
  align-self: end;
}

.bloomBannerText1 {
  text-align: left;
}

.bloomBannerText2 {
  margin-top: 20px;
  text-align: right;
}

.Uc2NEf::-webkit-scrollbar {
  display: none;
}

.aboutBloom {
  margin: 100px 60px;
  padding: 0 100px;
  /* display: grid; */
  /* grid-template-columns: 2fr 1fr; */
  /* align-items: center; */
  text-align: left;
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  /* width: 70%; */

}

.aboutBloomText {
  /* width: 50%; */
}

.bloomBtn {
  place-self: end;
  /* justify-self: center; */
  /* align-self: end; */
  border: none;
  border-radius: 25px;
  background-color: white;
  padding: 8px 16px;
  font-size: 20px;
  color: #F29CB5;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 8px;
  height: max-content;
  width: max-content;
  margin: 16px 0;
}